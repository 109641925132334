@font-face {
  font-family: 'RTLUnited';
  src: url('/assets/fonts/rtlUnited/RTLUnitedText-Regular.woff2') format('woff2'),
  url('/assets/fonts/rtlUnited/RTLUnitedText-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'RTLUnited';
  src: url('/assets/fonts/rtlUnited/RTLUnitedText-RegularItalic.woff2') format('woff2'),
  url('/assets/fonts/rtlUnited/RTLUnitedText-RegularItalic.woff') format('woff');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'RTLUnited';
  src: url('/assets/fonts/rtlUnited/RTLUnitedText-Bold.woff2') format('woff2'),
  url('/assets/fonts/rtlUnited/RTLUnitedText-Bold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}


@font-face {
  font-family: 'RTLUnited';
  src: url('/assets/fonts/rtlUnited/RTLUnitedText-BoldItalic.woff2') format('woff2'),
  url('/assets/fonts/rtlUnited/RTLUnitedText-BoldItalic.woff') format('woff');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'RTLUnited';
  src: url('/assets/fonts/rtlUnited/RTLUnitedText-Light.woff2') format('woff2'),
  url('/assets/fonts/rtlUnited/RTLUnitedText-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'RTLUnited';
  src: url('/assets/fonts/rtlUnited/RTLUnitedText-LightItalic.woff2') format('woff2'),
  url('/assets/fonts/rtlUnited/RTLUnitedText-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}
