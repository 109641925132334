@import "color";

.mat-paginator-container {
  justify-content: space-between !important;

  .mat-form-field-flex {
    border-bottom: none;
  }
  .mat-select-arrow-wrapper{
    padding-top: 24px;
  }
  .mat-select-arrow {
    color: #{$uiHighlight};
  }
  .mat-form-field-flex {
    border-bottom: 0px !important;
  }
}

td.mat-column-werbespotname{
  max-height: 48px;
  &:hover{
    overflow: visible !important;
    background-color: white;
    box-shadow: 1px 3px 3px rgb(0 0 0 / 20%);
    position: absolute;
    min-height: 48px;
    max-width: 150px;
    display: flex;
    align-items: baseline;
    max-height: 100% !important;
    padding-top: 4px;
  }
}