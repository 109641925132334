$rtlWhite: #FFFFFF;
$rtlBlack: #000000;
$rtlPink: #E50064;
$rtlBlue: #249BB5;
$rtlGrey: #F8F8F8;
$rtlGrey2: #ECEFF1;
$rtlGrey3: #74767C;
$rtlGrey4: #F1F2F4;

$rtlYellow: #EDC00CB3;
$rtlWarning: #FE8C26;

$rtlHeader1: #262626;

.heading-primary {
  color: $rtlHeader1;
}

.white {
  color: $rtlWhite;
}

$logoIndigo: #0C39ED;
$logoSky: #0CAAED;
$logoFuchsia: #ED0C93;

$error: #D4000D;
$warning: #FF8A00;
$success: #00AF2C;

$uiHighlight: #17181A;
$uiHighContrast: #A8AAAF;
$uiLowContrast: #F1F2F4;

$typoHighEmphasis: #020203;
$typoMediumEmphasis: #515258;
$typoLowEmphasis: #85888C;
$typoLowEmphasis2: #F7F8F9;

.success {
  color: #{$success} !important;
}

.error {
  color: #{$error} !important;
}

.disabled {
  color: #{$typoMediumEmphasis};
}
