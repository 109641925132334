@import "color";

.disabled {
  color: #{$typoLowEmphasis} !important;
}

.hide {
  display: none !important;
}

.nav-element {
  font-size: 17px;
  color: #{$typoMediumEmphasis};
  cursor: pointer;
  margin-right: 50px;
  padding-left: 10px;
  padding-right: 10px;
  line-height: 40px;
  outline: none;
  &.active {
    color: #{$typoHighEmphasis};
    border-bottom: 1px solid;
  }
  &.disabled {
    color: #{$typoLowEmphasis};
    pointer-events: none;
  }
}

.text-link {
  text-decoration: underline;
  cursor: pointer;
}

.page {
  height: 1800px;
}

.page-content-new {
  display: flex;
  width: 100%;
  padding-top: 30px;
}

.content-left-new {
  width: 100%;
  margin-right: 60px;
  max-width: 100%;
  overflow: hidden;
}

.content-right-new {
  width: 361px;
  border-left: 1px solid black;

  &.closed {
    width: 70px;
    transition: width 0.2s ease-in-out;
    flex-shrink: 0;
    flex-basis: auto;
  }

  &.opened {
    width: 361px;
    flex-shrink: 1;
    flex-basis: auto;
    transition:width 0.2s ease-in-out;
  }
}

.result-box-container{
  display: flex;
  justify-content: center;

}
