@import "color";


body {
  height: 100%;
  width: 100%;
  background-color: #{$rtlWhite};
  font-size: 17px;
  font-weight: 400;
}

h1 {
  color: #{$typoHighEmphasis};
  font-size: 45px !important;
  font-weight: 600 !important;
  margin: 30px 0 !important;
  letter-spacing: 0.45px !important;
}

h2 {
  color: #{$typoHighEmphasis};
  font-size: 32px !important;
  font-weight: 600 !important;
  margin: 20px 0 !important;
  letter-spacing: 0.32px !important;
}

h3 {
  font-size: 24px !important;
  font-weight: 600 !important;
  color: #{$typoHighEmphasis};
  padding-top: 16px;
  padding-bottom: 16px;
  letter-spacing: 0.24px !important;
}

.fs-xl {
  font-size: 32px !important;
  letter-spacing: 0.32px !important;
}

.fs-l {
  font-size: 24px !important;
  letter-spacing: 0.24px !important;
}

.fs-m {
  font-size: 17px !important;
  letter-spacing: 0.17px !important;
}

.fs-s {
  font-size: 13px !important;
  letter-spacing: 0.13px !important;
}


.content {
  background-color: #{$rtlWhite};
  padding: 36px;
  box-shadow: 0px 2px 6px #CFD8DC;

}

.headline {
  font-size: 36px;
  color: #{$rtlHeader1};
  padding-left: 120px;
  line-height: 40px;
}

.sub-headline {
  font-size: 24px;
  color: #{$rtlHeader1};
  padding-top: 36px;
  padding-left: 120px;
}


.page-container {
  width: 1137px;
  margin: 0 auto;
}

.panel-header {
  background-color: #{$rtlBlue};
  line-height: 72px;
}

.panel-content {
  min-height: 600px;
}

.panel-title {
  font-size: 24px;
  font-weight: bold;
  color: white;
}


.full-width {
  width: 100%;
}


.text-wrap-one-line {
  overflow-wrap: anywhere;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

.text-wrap-two-lines {
  overflow-wrap: anywhere;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.text-wrap-break-word {
  overflow-wrap: break-word;
}


.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

h3 {
  margin: 0px;
}

.action-button {
  font-size: 18px !important;
  width: 301px;
  height: 60px;
  background-color: black;
  box-shadow: none !important;
  border-radius: 0px !important;
  color: white;

  &.bottom {
    bottom: 20px;
    position: absolute;
  }

  &.secondary:not([disabled]) {
    background: white;
    color: black;
    border: 1px solid black;
  }

  &.cancel {
    width: 100px;
  }

  &.small {
    width: 120px !important;
  }

  &.extra-small {
    width: auto !important;
    height: 35px !important;
    box-sizing: unset;
    min-width: 50px;
  }

  &.auto {
    width: auto !important;
  }
}

.icon-prefix {
  padding-left: 16px;
  padding-right: 50px;
  font-size: 24px !important;

  &.large {
    font-size: 30px !important;
  }
}

.icon-suffix {
  font-size: 24px !important;

  &.large {
    font-size: 30px !important;
  }
}

.pointer {
  cursor: pointer;
}

.l-h-22 {
  line-height: 22px;
}

.l-h-30 {
  line-height: 30px;
}

.cursor-pointer {
  cursor: pointer;
}


.warning {
  border: 2px solid #{$rtlWarning};
  text-align: center;
}

.warning-icon {
  height: 20px;
  padding-right: 10px;
}


.pull-right {
  float: right;
}

.pull-left {
  float: left;
}

.actions-container {
  margin: 0px 40px;
  padding-top: 20px;
}

.close-container {
  text-align: right;
  margin-right: -24px;
  font-size: 42px;
}

.close-icon {
  font-size: 40px;
  width: unset !important;
  height: unset !important;
  cursor: pointer;
}

.alert-dialog {
  z-index: 1100;
}

.d-none {
  display: none;
}
