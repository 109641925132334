@import "color";
@import "angular-material/mat-select.scss";
@import "angular-material/mat-calendar.scss";
@import "angular-material/mat-form-field.scss";

mat-form-field {
  padding-top: 0px !important;
  font-size: 17px !important;
}

mat-label {
  font-size: 13px !important;
  color: #{$uiHighlight}
}

.mat-form-field-flex {
  padding-top: 0px !important;
}

mat-form-field.datepicker-field .mat-form-field-flex {
  height: 48px;
  line-height: 48px;

  & input {
    padding-bottom: 5px;
  }
}

mat-datepicker-toggle {
  & button {
    height: 48px !important;
  }
}

.with-icon .mat-form-field-infix{
  border-top: 0px !important;
  padding: 0px !important;

  & input {
    padding-bottom: 5px;
  }
}

mat-form-field:not(.with-icon) .mat-form-field-infix{
  border-top: 0px !important;
  padding: 0px !important;

  & input {
    height: 48px;
  }
}

.mat-form-field-prefix, .mat-form-field-suffix {
  height: 48px!important;
}
.mat-form-field-prefix .mat-icon, .mat-form-field-suffix .mat-icon {
  line-height: 48px !important;
}

mat-hint {
  width: 100%;
}

.mat-form-field-subscript-wrapper {
  padding: 0px !important;
}

.mat-form-field-hint-wrapper {
  display: unset !important;
}

input::placeholder{
  color: #{$typoMediumEmphasis} !important;
}

label.required:after,
mat-label.required:after {
  content: ' *';
}

.mat-form-field-appearance-fill .mat-form-field-flex {
  border-radius: 0px !important;
}

.mat-radio-label-content {
  // font-weight: 300 !important;
}

.mat-progress-spinner circle, .mat-spinner circle {
  stroke: #{$logoSky};
  stroke-width: 6% !important;
}

button:focus {
  outline: none;
}

mat-radio-button {
  padding-right: 16px;
}

mat-checkbox {
  padding-right: 16px;
}

.mat-form-field.mat-focused .mat-form-field-label {
  color: rgba(0, 0, 0, 0.6);
}

.radio-product-selection .mat-radio-outer-circle {
  border-color: black !important;
}

.radio-product-selection.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(0,0,0,.38) !important;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle {
  background-color: black !important;
}

mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element, .mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(0,0,0,.38) !important;
}

.mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle, .mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, .38) !important;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #{$rtlBlack};
}

.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element, .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: black !important;
}

.mat-radio-persistent-ripple {
  background-color: black !important;
}

.mat-radio-button .mat-ripple-element {
  background-color:  #{$rtlBlack} !important;
}

/* mat input */

.mat-form-field-underline {
  display: none;
}


.mat-focused .mat-form-field-flex {
  border-bottom: 1px solid !important;
  //background-color: #{$rtlWhite};
}

.mat-form-field-flex {
  border-bottom: 1px solid #{$uiHighlight}!important;
}

.disabled .mat-form-field-flex {
  border-bottom: none !important;
  background-color: #{$rtlGrey2} !important;
}


.active .mat-form-field-appearance-fill, .active .mat-form-field-flex {
  //background-color: #{$rtlWhite} !important;
 // border: 2px solid !important;
}


.cdk-overlay-dark-backdrop {
  background: #{$uiHighlight} !important;
  opacity: 0.7 !important;
}

.cdk-global-overlay-wrapper {
  //height: auto !important;
  // align-items: normal !important;
}

.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: black !important;
}

/**
mat autocomplete
 */

.mat-autocomplete-panel {
  border: 1px solid #{$uiHighContrast};
  margin-top: -1px;
  border-radius: 0px !important;
  box-shadow: none !important;
}

.mat-option{
  color: #{$typoMediumEmphasis};
}

.mat-option.mat-active {
  background-color: #{$rtlWhite};
  color: #{$typoMediumEmphasis};
}

.mat-option:hover:not(.mat-option-disabled), .mat-option:focus:not(.mat-option-disabled) {
  background-color: #{$uiLowContrast};
  color: #{$uiHighlight} !important;
}

.mat-standard-chip {
  border-radius: 0px !important;
}

.mat-chip.mat-standard-chip{
  background-color: #{$uiLowContrast};
  border: 1px solid #{$uiHighContrast};
}

.mat-chip.mat-standard-chip::after {
  background: transparent;
}

.mat-chip.mat-standard-chip .mat-chip-remove {
  color: #{$typoHighEmphasis};
  opacity: 1;
}

.mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 1;
}
.mat-chip .mat-chip-trailing-icon.mat-icon, .mat-chip .mat-chip-remove.mat-icon {
  font-size: 24px !important;
  height: 23px !important;
}

.mat-chip-ripple {
  padding-bottom: 22px;
  position: inherit !important;
}
/**
mat input
 */

.mat-input-element {
  color: #{$typoHighEmphasis} !important;
}
/**
mat select
 */

.mat-select-panel {
  border: 1px solid #{$rtlBlack};
}

.mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background-color: #{$rtlBlack};
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #{$rtlWhite};
}

.mat-select-trigger {
  line-height: 48px;
  align-items: flex-start !important;
}

.mat-select-arrow-wrapper{
  padding-top: 48px;
}

mat-select{
  height: 48px;
}


/**
mat checkbox
 */
.mat-checkbox-inner-container {
  height: 23px !important;
  width: 23px !important;
}

/**
mat dialog
 */
mat-dialog-container {
  border: 1px solid #{$uiHighlight};
  border-radius: 0px;
}

/**
mat autocomplete
 */

mat-chip {
  width: auto !important;
}
.mat-autocomplete-panel {
  border: 1px solid #{$uiHighlight};
  margin-top: -1px;
}

.mat-chip.mat-standard-chip {
  background-color: #{$rtlGrey4};
}

.mat-chip .mat-chip-trailing-icon.mat-icon, .mat-chip .mat-chip-remove.mat-icon {
  font-size: 24px !important;
  height: 23px !important;
}

.mat-chip-list-wrapper {
  display: unset !important;
}

.mat-chip-list-wrapper input.mat-input-element, .mat-chip-list-wrapper .mat-standard-chip {
  width: 100%;
  padding-bottom: 0px !important;
}


/**
mat select
 */

.mat-select-panel {
  border: 1px solid #{$uiHighlight};
  border-radius: 0px !important;
  box-shadow: none !important;
}

.mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background-color: #{$uiLowContrast};
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #{$typoHighEmphasis};
}

.mat-input-element:disabled, .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after,
.mat-input-element:disabled::placeholder {
  color: rgba(0,0,0,.38) !important;
}

/**
mat checkbox
 */
.mat-checkbox-inner-container {
  height: 23px !important;
  width: 23px !important;
}

.mat-checkbox-background, .mat-checkbox-frame {
  border-radius: 0px !important;
}
/**
mat dialog
 */

mat-dialog-container {
  border: 1px solid #{$uiHighlight};
  border-radius: 0px;
  padding: 30px 96px !important;
}

mat-dialog-content {
  min-height: 120px;
  padding-bottom: 24px !important;
}

.mat-dialog-actions {
  margin-bottom: 0px !important;
  padding: 0px !important;
}

mat-dialog-actions {
  margin: 0px -40px;
  border-top: 1px solid #{$uiHighlight};
}

.mat-dialog-container {
  border-radius: 0px !important;
}

.cdk-global-overlay-wrapper {
  background-color: rgba(0,0,0,0.2) !important;
}
/**
mat calendar
 */

.mat-datepicker-content {
  display: block;
  border-radius: 0px !important;
  border: 1px solid #{$uiHighlight};
  box-shadow: none !important;

}

.mat-datepicker-content .mat-calendar {
  width: 291px !important;
}

.mat-calendar-body-selected {
  background-color: #{$rtlBlack};
  box-shadow: none !important;
}

.mat-datepicker-toggle, .mat-datepicker-content .mat-calendar-next-button, .mat-datepicker-content .mat-calendar-previous-button {
  color: #{$rtlBlack};
}

.mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: rgba(0,0,0,.6) !important;
}


.mat-select-panel-wrap {
  padding-left: 10px;
}

.mat-select-panel {
  max-height: 400px;
}
.mat-select-search-inner {
  border-left: none !important;
  border-right: none !important;
}

.mat-tree-node, .mat-nested-tree-node{
  font-size: 17px !important;
}

.default-dialog-content-height {
  height: 825px;
}
