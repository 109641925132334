
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$panelytics-frontend-primary: mat.define-palette(mat.$indigo-palette);
$panelytics-frontend-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$panelytics-frontend-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$panelytics-frontend-theme: mat.define-light-theme((
  color: (
    primary: $panelytics-frontend-primary,
    accent: $panelytics-frontend-accent,
    warn: $panelytics-frontend-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($panelytics-frontend-theme);



@import "/src/app/scss/rtl-united-theme";
@import "/src/app/scss/rtl-united-layout";
@import "/src/app/scss/layout-full-width";
@import "/src/app/scss/base";
@import "/src/app/scss/color";
@import "/src/app/scss/utils";
@import "/src/app/scss/typography";
@import "/src/app/scss/rtl-grid";
@import "/src/app/scss/material";

@import "../node_modules/bootstrap-css-only/css/bootstrap.css";
@import "../node_modules/material-icons/iconfont/material-icons.css";
@import "../node_modules/ag-grid-community/dist/styles/ag-grid.css";
@import "../node_modules/ag-grid-community/dist/styles/ag-theme-material.css";
html, body {
  height: 100%;
}

html, body { height: 100%; }
body {
  margin: 0;
  font-family: RTLUnited, "Helvetica Neue", sans-serif !important;
  //font-size: 17px !important;

}
body * {
  letter-spacing: 0.7px !important;
}

$custom-typography: mat.define-typography-config(
  $font-family: 'RTLUnited'
);
@include mat.core($custom-typography);
