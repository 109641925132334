.flex{
  display: flex;
}
.justify-space-between{
  justify-content: space-between;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}


.left {
  display: flex;
  align-items: center;
  justify-content: left;
}

.right {
  display: flex;
  align-items: center;
  justify-content: right;
}


.p-left-6 {
  padding-left: 6px !important;
}

.p-left-24 {
  padding-left: 24px !important;
}

.p-left-30 {
  padding-left: 30px !important;
}

.p-right-6 {
  padding-right: 6px !important;
}

.p-right-24 {
  padding-right: 24px !important;
}

.p-right-30 {
  padding-right: 30px !important;
}

.p-top-6 {
  padding-top: 6px !important;
}

.p-top-16 {
  padding-top: 16px !important;
}

.p-top-24 {
  padding-top: 24px !important;
}

.p-top-30 {
  padding-top: 30px !important;
}

.p-top-36 {
  padding-top: 36px !important;
}

.p-top-60 {
  padding-top: 60px !important;
}

.m-left-32 {
  margin-left: 32px !important;
}

.m-right-10 {
  margin-right: 10px !important;
}

.m-top-24 {
  margin-top: 24px !important;
}

.p-bottom-12 {
  padding-bottom: 12px !important;
}

.p-bottom-24 {
  padding-bottom: 24px !important;
}

.p-bottom-30 {
  padding-bottom: 30px !important;
}

.no-padding {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.no-padding-top {
  padding-top: 0px !important;
}

.no-padding-bottom {
  padding-bottom: 0px !important;
}
.no-padding-right {
  padding-right: 0px !important;
}
