@import "/src/app/scss/color";

.mat-select-panel {
  border: 1px solid #{$rtlBlack};
}

.mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background-color: #{$rtlBlack};
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #{$rtlWhite};
}

.mat-select-trigger {
  line-height: 48px;
}

.mat-select-arrow-wrapper{
  padding-top: 48px;
}

mat-select{
  height: 48px;
}
